import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Buttons } from "@common/components"
import { ArrowRight } from '@styled-icons/bootstrap'

const Wrapper = styled.section`
  ${tw`flex flex-col w-full mx-auto px-10 py-16 bg-new-secondary-bg`}
`

const TitleGroup = styled.div`
  ${tw`flex`}
`

const Title = styled.h3`
  ${tw`w-full text-2xl font-bold text-white mb-16`}
`

const ExploreMore = styled.a`
  ${tw`text-white leading-normal w-40 font-bold hidden`}
  ${tw`md:(block)`}
`

const ExploreMoreBotton = styled.a`
  ${tw`text-white leading-normal w-40 font-bold mt-10`}
  ${tw`md:(hidden)`}
`

const StyledArrowRight = styled(ArrowRight)`
  ${tw`w-4`}
`

const List = styled.ul`
  ${tw`grid grid-cols-1 gap-12`}
  ${tw`md:(grid-cols-2)`}
  ${tw`lg:(grid-cols-3)`}
  ${({ maxItems }) => maxItems > 3 ? tw`lg:(grid-cols-4)` : tw`lg:(grid-cols-3)`}
`

const ListItem = styled.li`
  ${tw`w-full p-5 shadow-xl bg-white`}
`

const ItemTitle = styled.h2`
  ${tw`text-lg text-gray-900 tracking-tight leading-6 mb-2 mt-4`}
`

const ItemsGroupAlternative = ({ title, items, buttonText, exploreMore }) => {
  return (
    <Wrapper>
      <TitleGroup>
        <Title>{title}</Title>
        <ExploreMore href={exploreMore} title="Explore more...">
          Explore more <StyledArrowRight />
        </ExploreMore>
      </TitleGroup>
      <List maxItems={items.length}>
        {items.map((item) => (
          <ListItem key={item.id}>
            {item.image && (<Link to={item.slug} title={item.title}><Img
              tw="w-full mb-6"
              fluid={{
                ...item.image.childImageSharp.fluid,
                aspectRatio: 380 / 255,
              }}
              alt={item.title}
            /></Link>)}
            <ItemTitle>
              {item.title}
            </ItemTitle>
            <p tw="text-sm text-gray-800">
              {item.childMarkdownRemark.excerpt}
            </p>
            <Link to={item.slug} title={item.title}><Buttons.Secondary tw="mt-8">{buttonText}</Buttons.Secondary></Link>
          </ListItem>
        ))}
      </List>
      <ExploreMoreBotton href={exploreMore} title="Explore more...">
        Explore more <StyledArrowRight />
      </ExploreMoreBotton>
    </Wrapper>
  )
}

export default ItemsGroupAlternative
