import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Buttons } from "@common/components"
import { ArrowRight } from '@styled-icons/bootstrap'

const Wrapper = styled.section`
  ${tw`flex flex-col w-full mx-auto px-10 my-16`}
`

const TitleGroup = styled.div`
  ${tw`flex`}
`

const Title = styled.h3`
  ${tw`w-full text-2xl font-bold text-primary mb-16`}
`

const ExploreMore = styled.a`
  ${tw`text-gray-900 leading-normal font-bold w-40 hidden`}
  ${tw`md:(block)`}
`

const ExploreMoreBotton = styled.a`
  ${tw`text-gray-900 leading-normal font-bold mt-10`}
  ${tw`md:(hidden)`}
`

const StyledArrowRight = styled(ArrowRight)`
  ${tw`w-4`}
`

const List = styled.ul`
  ${tw`grid grid-cols-1 gap-12`}
  ${tw`md:(grid-cols-2)`}
  ${tw`lg:(grid-cols-3)`}
  ${({ maxItems }) => maxItems > 3 ? tw`lg:(grid-cols-4)` : tw`lg:(grid-cols-3)`}
`

const ListItem = styled.li`
  ${tw`w-full p-5 shadow-xl`}
`

const TopTitle = styled.h2`
  ${tw`text-lg text-primary tracking-tight leading-6 mb-2`}
`

const ItemTitle = styled.h2`
  ${tw`text-lg text-gray-900 tracking-tight leading-6`}
`

const ItemsGroup = ({ title, items, buttonText, alternativeButton, exploreMore }) => {
  const Button = styled(alternativeButton ? Buttons.SecondaryAlternative : Buttons.Secondary)`${tw`mt-8`}`;

  return (
    <Wrapper>
      <TitleGroup>
        <Title>{title}</Title>
        <ExploreMore href={exploreMore} title="Explore more...">
          Explore more <StyledArrowRight />
        </ExploreMore>
      </TitleGroup>
      <List maxItems={items.length}>
        {items.map((item) => (
          <ListItem key={item.id}>
            {item.image && (<Link to={item.slug} title={item.title}><Img
              tw="w-full mb-6"
              fluid={{
                ...item.image.childImageSharp.fluid,
                aspectRatio: 380 / 255,
              }}
              alt={item.title}
            /></Link>)}
            {item.topTitle && <TopTitle>
              {item.topTitle}
            </TopTitle>}
            <ItemTitle>
              {item.title}
            </ItemTitle>
            {item.childMarkdownRemark &&
              <p tw="text-sm text-gray-800 mt-4">
                {item.childMarkdownRemark.excerpt}
              </p>
            }
            <Link to={item.slug} title={item.title}><Button>{buttonText}</Button></Link>
          </ListItem>
        ))}
      </List>
      <ExploreMoreBotton href={exploreMore} title="Explore more...">
        Explore more <StyledArrowRight />
      </ExploreMoreBotton>
    </Wrapper>
  )
}

export default ItemsGroup
