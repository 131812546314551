import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { Buttons } from "@common/components"
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const BgImage = styled(BackgroundImage)`
  ${tw`flex h-170`}
  ${tw`md:(h-100)`}
  ${tw`xl:(h-128)`}
  ${tw`2xl:(h-176)`}
`

const Text = styled.div`
  ${tw`flex items-center p-8 mt-72`}
  ${tw`md:(ml-20 mt-0 max-w-md)`}
  ${tw`xl:(max-w-2xl)`}
  ${tw`2xl:(ml-40)`}
`

const Content = styled.div`
  ${tw`flex-col`}
`

const Title = styled.h3`
  ${tw`w-full text-xl font-bold text-primary mb-8`}
`

const GetYourMedicalCard = ({ data }) => {
  const { getYourMedicalCard, getYourMedicalCardMobile } = data;

  const sources = [
    getYourMedicalCardMobile.childImageSharp.fluid,
    {
      ...getYourMedicalCard.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <BgImage fluid={sources}>
      <Text>
        <Content>
          <Title>GET YOUR MEDICAL MJ CARD</Title>
          <p tw="mb-2 text-xl">It’s online, fast, and easy. We’ve issued over 200,000 cards to date!</p>
          <p tw="mb-10 text-xl">Plus, you don’t pay if you aren’t approved.</p>
          <Link to='/get-started'><Buttons.Primary>GET YOUR MEDICAL CARD</Buttons.Primary></Link>
        </Content>
      </Text>
    </BgImage>
  )
}

export default GetYourMedicalCard
