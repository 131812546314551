import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Buttons } from "@common/components"

const Center = styled.div`
  ${tw`relative mx-auto`}
  ${tw`md:(h-auto)`}
`

const StyledBackgroundImage = styled(BackgroundImage)`
  ${tw`bg-right-bottom flex items-start justify-center w-screen pb-10 h-150`}
  ${tw`md:(h-176 py-0 items-center justify-start)`}
`

const TextWrapper = styled.div`
  ${tw`absolute flex flex-col items-start z-10 m-4 bg-white p-10 shadow-2xl`}
  ${tw`md:(mt-36 ml-24 p-10)`}
`

const HelloMDPresents = styled.div`
  ${tw`text-primary font-semibold`}
`

const Divider = styled.hr`
  ${tw`border-t-4 w-10 border-primary`}
`

const Title = styled.h1`
  ${tw`text-2xl font-semibold tracking-tight leading-tight mt-0 mb-6`}
  ${tw`md:(text-4xl max-w-sm text-left)`}
`

const ExtraText = styled.h3`
  ${tw`text-xl tracking-tight leading-tight my-6`}
  ${tw`md:(text-xl max-w-sm text-left)`}

  & > a {
    ${tw`underline`}
  }
`

const TopicOfWeek = ({ data, title, subtitle, link }) => {
  const { banner, bannerMobile } = data;

  const sources = [
    bannerMobile.childImageSharp.fluid,
    {
      ...banner.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <section>
      <Center>
        <TextWrapper>
          <HelloMDPresents>HELLOMD PRESENTS</HelloMDPresents>
          <Title>{title}</Title>
          <Divider />
          <ExtraText>{subtitle}</ExtraText>
          <Link to={link}><Buttons.Primary>READ MORE</Buttons.Primary></Link>
        </TextWrapper>
        <StyledBackgroundImage
          Tag="section"
          fluid={sources}
        />
      </Center>
    </section>
  )
}

export default TopicOfWeek
