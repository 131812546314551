import React, { useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import CannabisPc from '../../images/cannabis-pc.svg'
import { Buttons } from "@common/components"

import { ipInfo } from '../../helpers'

const Wrapper = styled.div`
  ${tw`flex justify-center items-center py-20`}
`

const Content = styled.div`
  ${tw`flex-col items-center text-center`}
`

const Icon = styled.img`
  ${tw`inline mb-4`}
`

const Title = styled.h3`
  ${tw`w-full text-2xl text-primary mb-2`}
`

const Fields = styled.div`
  ${tw`flex-col mt-12`}
`

const Email = styled.input`
  ${tw`placeholder-black border-2 mb-2 p-2 w-80 text-center`}
  ${tw`md:(mx-4)`}
`

const Zipcode = styled.input`
  ${tw`placeholder-black border-2 mb-2 p-2 mx-4 w-80 text-center`}
  ${tw`md:(w-40)`}
`

const SignUp = styled(Buttons.Primary)`
  ${tw`px-10 cursor-pointer`}
`

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [zipcode, setZipcode] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const ipInfoResponse = await ipInfo.get()
      await fetch(`${process.env.API_URL}/leads`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          country: ipInfoResponse && ipInfoResponse.country,
          region: ipInfoResponse && ipInfoResponse.region,
          zipcode,
        }),
      })

      alert('Thank you!');
    } catch (error) {
      console.error(error)
      alert('Something went wrong.')
    }
  }

  return (
    <Wrapper>
      <Content>
        <Icon src={CannabisPc} />
        <Title>THE PERFECT DOSE OF ADVICE</Title>
        <p>Delivered right to your inbox. Sign-up for news, deals, and more!</p>
        <Fields>
          <form onSubmit={handleSubmit}>
            <Email type="email" placeholder="Your Email" onChange={(e) => setEmail(e.target.value.toLowerCase())} required></Email>
            <Zipcode placeholder="Your Zipcode" onChange={(e) => setZipcode(e.target.value.toLowerCase())} required></Zipcode>
            <button type="submit">
              <SignUp>SIGN UP</SignUp>
            </button>
          </form>
        </Fields>
      </Content>
    </Wrapper>
  )
}

export default Newsletter