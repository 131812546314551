import React, { useState } from 'react'
import Img from 'gatsby-image'
import tw from 'twin.macro'
import styled from 'styled-components'
import { Buttons } from '@common/components'

import ChatWithUsModal from './ChatWithUsModal'

const Wrapper = styled.div`
  ${tw`flex-1 flex-col`}
  ${tw`md:(flex flex-row)`}
`

const Image = styled(Img)`
  ${tw`flex-1 w-full`}
`

const Text = styled.div`
  ${tw`flex-1 flex items-center p-10`}
  background-color: #F5F6F6
`

const Content = styled.div`
  ${tw`flex-col`}
`

const Title = styled.h3`
  ${tw`w-full text-2xl font-bold text-primary mb-8`}
`

const MJMoreConfidently = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { mJMoreConfidently } = data

  return (
    <Wrapper>
      <Image fluid={mJMoreConfidently.childImageSharp.fluid} />
      <Text>
        <Content>
          <Title>NEED A MEDICAL MARIJUANA CARD?</Title>
          <p tw="mb-10 text-xl">
            Have a medical consultation with a licensed practitioner online and
            get your marijuana card today. Receive expert cannabis advice – it
            fast, easy, and discreet. You only pay if you’re approved!
          </p>
          <span tw="cursor-pointer" onClick={() => setIsModalVisible(true)}>
            <Buttons.SecondaryAlternative>
              CHAT WITH US
            </Buttons.SecondaryAlternative>
          </span>
          <ChatWithUsModal
            isVisible={isModalVisible}
            setIsVisible={setIsModalVisible}
          />
        </Content>
      </Text>
    </Wrapper>
  )
}

export default MJMoreConfidently
