import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'

import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'

const Wrapper = styled.div`
  ${tw`absolute inset-0 flex items-center justify-center`}
`

const Behind = styled.div`
  ${tw`absolute inset-0 z-30 w-full h-full opacity-50 bg-black`}
`

const Content = styled.div`
  ${tw`flex flex-col text-center items-center mt-8 bg-white z-40 p-10 overflow-auto	md:(w-1/4 text-left justify-center mt-0)`}
`

const ChatWithUsModal = ({ isVisible, setIsVisible }) => {
  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('no-scroll')
      return
    }
    document.body.classList.remove('no-scroll')
  }, [isVisible])

  const closeModal = () => {
    setIsVisible(false)
  }

  return isVisible ? (
    <Wrapper>
      <Behind />
      <Content>
        <span
          onClick={closeModal}
          tw="relative flex -top-8 -right-52 items-center justify-center w-8 h-8 text-black text-2xl leading-none cursor-pointer"
        >
          <CloseOutline tw="w-8" />
        </span>
        <iframe height="720" title="Embedded Wufoo Form" allowtransparency="true" frameborder="0" style={{ width: '100%', border: 'none' }} src="https://hellomd.wufoo.com/embed/pbzu2rw054nkdf/"> <a href="https://hellomd.wufoo.com/forms/pbzu2rw054nkdf/">Fill out my Wufoo form!</a> </iframe>
      </Content>
    </Wrapper>
  ) : null
}

export default ChatWithUsModal
